.search-main-form-v1 {

	.form-bx-area {
		background-color: var(--color-white);
		border-radius: 5px;
	}

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;

		select#fc_bedrooms_min {
			padding: 0.375rem 0.5rem;
		}

		.fa,
		.far {
			position: absolute;
			right: 1rem;
			color: #999;
			top: 10px;
			right: 10px;
			font-size: 1rem;

			&.fa-bed {
				z-index: 5;
				width: 26px;
				background-color: #fff;
				right: 3px;
			}

			&.fa-map-marker {
				z-index: 5;
				width: 26px;
				background-color: #fff;
				right: 3px;
				text-align: center;
			}


			@media (max-width: 992px) {
				font-size: 16px;
				bottom: unset;
				right: 10px;
				top: 14px;
			}
		}

	}


	.form-control {
		border-radius: 5px;
		font-size: 16px;
		cursor: pointer;
		position: relative;
		z-index: 1;
		background: transparent;
		padding-right: 25px !Important;

		@media (min-width:1200px) {
			font-size: 1rem;
			padding: .375rem .75rem;
			min-height: 38px;
			height: auto;
		}

		font-size: 1rem;
		padding: .375rem .75rem;
		min-height: 38px;
		height: auto;


		&.btn-lg {

			font-size: 16px;

			@media (min-width:1200px) {
				font-size: 14px;
				padding: 0 1rem;
				height: auto;
				min-height: 55px;
				padding-right: 38px;
			}

			@media (max-width:1199px) {
				font-size: 13px;
				height: auto;
				min-height: 50px;
			}

			@media (max-width:992px) {
				font-size: 14px;
				height: auto;
				min-height: 50px;
			}

		}

	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}


}