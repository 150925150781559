.unit-card-v2 {

	.content {
		padding: 10px;
		display: flex;
		flex-direction: column;
		min-height: 190px;
		justify-content: space-between;
		border: 1px solid var(--color-grey-normal);
		border-radius: 0 0 8px 8px;
		margin: 0 25px;
		top: -35px;
		border-radius: 5px;
		position: relative;
		box-shadow: 0 2px 6px rgb(0 0 0 / 20%);

		background-color: var(--white);

		.region-type {
			font-size: var(--font-size-md);
		}

		.fa {
			padding-right: 5px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;
			margin: 15px -15px 0;
			padding: 5px 15px 0;
			border-top: 1px solid var(--color-grey-normal);
			align-items: center;

			.label {
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: 12px;
				color: var(--color-primary);
			}

			.btn {
				font-size: var(--font-size-md);
				color: var(--color-white);
			}

		}


	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;
		border-radius: 8px;
		display: block;
		box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
		overflow: hidden;

		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;
			border-radius: 8px 8px 0 0;
		}

		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}

		.unit-distance {
			position: absolute;
			font-size: 12px;
			line-height: 30px;
			bottom: 10px;
			background-color: var(--color-secondary-light);
			border-radius: 8px;
			padding: 0 15px;
			font-weight: 600;
			border-radius: 15px;
			color: var(--color-white);
			right: 26px;
			bottom: 45px;
		}

		.favorite {
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}
}