.searchbar-v1 {
	position: absolute;
	bottom: 70px;
	text-align: center;
	width: 100%;
	z-index: 5;
	padding: 0 50px;

	@media (max-width: 576px) {
		padding: 0px 10px;

	}

	@media (max-width: 767px) {
		top: 60vh;
	}

	@media (max-height: 600px) {
		top: 400px;

	}

	.title {
		color: #fff;
		background-color: var(--color-primary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.searchbar {
		display: inline-block;
		text-align: left;
		background-color: #fff;
		border-radius: 8px;

		@media (max-width: 992px) {
			padding: 10px 10px;
		}
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;

		@media (min-width: 1141px) {
			padding-left: 15px;
		}

		@media (max-width: 992px) {
			flex-wrap: wrap;
		}

		.form-flex {

			padding: 20px 22px;
			border-right: 0px solid #f4f4f4;
			padding: 15px 8px 5px;
			width: 30%;

			.form-flex-area {
				&.map-bl {
					padding-right: 0 !important;

					.shadow {
						background-color: #fff;

						@media (max-width:1140px) {
							right: 20px;
						}

						@media (min-width:1141px) {
							right: 2px;
							width: 60px;
						}
					}

					.small {
						@media (max-width:1140px) {
							right: 2px;
							width: 15px;
							background-color: #fff;
							height: 26px;
						}
					}

				}
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 0px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			.far,
			.fas {
				position: absolute;
				right: 2px;
				top: 44px;
				color: #000;
				z-index: 2;
				font-size: 20px;
				pointer-events: none;

				@media (max-width:1340px) {
					top: 28px;
				}

				@media (max-width:1140px) {
					top: 13px;

				}
			}

			.shadow {
				font-size: 65px;
				top: 0;
				color: #e5e5e5;
				z-index: 1;
				right: 14px;
				position: absolute;
				box-shadow: none !important;

				@media (max-width:1340px) {
					font-size: 48px;
				}

				@media (max-width:1140px) {
					font-size: 37px;
					top: -4px;
					/*opacity: .5;*/
				}

				@media (max-width:640px) {
					font-size: 28px;
					top: 5px;
				}
			}


			.form-control {
				padding: 5px 30px 5px 0;
				height: 70px;
				min-height: 40px;
				background-color: transparent;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: none;
				line-height: 20px;
				padding: 28px 10px 15px;
				min-width: 230px;
				line-height: 28px;

				@media (max-width:1340px) {
					font-size: 18px;
					padding-top: 20px;
					padding-bottom: 20px;
				}

				@media (max-width:1140px) {
					font-size: 16px !important;
					padding: 10px 5px;
				}
			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
				padding-right: 125px;
				padding-left: 2px;
				border-bottom: 2px solid #e5e5e5;

				@media (max-width: 767px) {
					padding-right: 40px;
				}
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}

		.form-btn {

			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 0px;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}

			.btn {
				width: 110px;
				height: 120px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;
				border-radius: 0 8px 8px 0;
				margin-left: 15px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
					margin-left: 0;
					border-radius: 8px;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}