.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax-img-new.jpeg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	&:before {
		background-color: var(--white);
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		top: -1px;
		content: "";
	}

	&:after {
		background-color: var(--white);
		height: 130px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		bottom: -1px;
		content: "";
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.parallax-box {

		padding: 80px;
		position: relative;
		z-index: 1;
		background-color: var(--color-primary);
		color: var(--white);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		max-width: 800px;
		border-radius: 8px;

		@media (max-width:992px) {
			min-height: 440px;
			padding: 20px;
		}

		.title {
			color: var(--color-white);
			font-family: 'Mr De Haviland', cursive;
			font-size: 80px;
			font-weight: 400;

			@media (max-width:992px) {
				font-size: 48px;
			}
		}

		.text {
			color: var(--color-white);
		}
	}

}