.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-list-section {
		.owl-item {
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, .7);
				content: ' ';
				transition: all 0.5s ease;
			}

			&.active {
				&:before {
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		&.nxt-prev-btns {
			position: relative;

			.owl-nav {
				position: absolute;
				top: 45%;
				left: 0;
				width: 100%;



				@media (max-width:992px) {
					/*display: none;*/
				}


				button {
					width: 70px;
					height: 70px;
					border-radius: 50%;
					border: 1px solid #ddd !important;
					border-radius: 50%;
					box-shadow: 0 0 10px rgba(0, 0, 0, .5);
					background-color: var(--white) !important;
					position: absolute;
					left: 50px;
					font-size: 34px;
					text-align: center;

					span {
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;

						&:before {
							color: var(--black);
							position: relative;
							left: 3px;
							font-family: Font Awesome\ 5 Free;
							color: #000;
						}
					}

					&:hover {
						span {
							opacity: .3;
						}
					}

					&.owl-next {
						position: absolute;
						right: 50px;
						left: initial;

						span {

							&:before {
								content: "\f054";
								left: 6px;
							}
						}
					}

					&.owl-prev {
						span {

							&:before {
								content: "\f053";

							}
						}
					}

					@media (max-width:767px) {
						width: 40px;
						height: 40px;
						left: 20px;
						font-size: 20px;
						line-height: 38px;

						&.owl-next {
							right: 20px;
						}

						span {
							&:before {
								font-size: 20px;
							}
						}
					}
				}
			}
		}

		.item {
			overflow: hidden;
			/* min-height: 600px; */
			height: calc(77vh - 70px);

			@media (max-width:1199px) {
				height: calc(50vh - 70px);
			}

			@media (max-width:992px) {
				height: calc(50vh - 70px);
			}

			@media (max-width:767px) {
				/* min-height: 450px; */
				height: calc(60vh - 80px);
			}



			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.v-img-gallery {
		max-width: 500px;
		position: relative;
		top: -25px;
		z-index: 1;
		width: 100%;
		display: inline-flex;
		justify-content: flex-end;
		border-radius: 5px;
		overflow: hidden;

		@media (max-width:992px) {
			top: -25px;
			height: auto;
			justify-content: flex-start;
		}

		@media (max-width:380px) {
			top: -45px;
		}

		a {
			position: relative;
			display: inline-block;
			border: 3px solid var(--white);
			box-shadow: 3px 3px 2px rgba(0, 0, 0, .1);
			background: var(--white);


			&:nth-child(n + 4) {
				display: none;
			}

			&:nth-child(3) {
				&:before {
					z-index: 5;
					content: "mehr +";
					position: absolute;
					color: #fff;
					background-color: rgba(0, 0, 0, .5);
					width: 100%;
					height: 100%;
					text-align: center;
					display: flex;
					font-weight: 600;
					text-align: center;
					left: auto;
					right: auto;
					align-items: center;
					align-content: center;
					justify-content: center;
					border-radius: 4px;
					font-size: 20px;

					@media (max-width:767px) {
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
		}

		.img-thumbnail {
			border: none;
			padding: 0;
			width: 100%;

		}

	}

	.main-section {

		.page-header {
			margin-top: 0;
			border-bottom: none;
			margin-bottom: 0;
			position: relative;

			.unit-fav-star {
				position: absolute;
				left: 0;
				top: -90px;
				z-index: 10;
				max-width: 450px;
				width: 100%;

				@media (max-width:992px) {
					width: 100%;
					display: flex;
					justify-content: space-between;
					top: -100px;
				}


				.stars {
					font-size: 18px;
					margin-right: 5px;
					letter-spacing: 2px;
					display: inline-block;
					color: var(--secondary);

					@media (max-width:992px) {
						font-size: 13px;

					}
				}

				.btn-icon-fav {
					max-width: 280px;
					display: inline-block;
					background-color: rgba(255, 255, 255, .7);
					border-radius: 30px;
					font-size: 18px;
					border: none;
					padding-top: 10px;
					font-family: var(--d-font2);

					@media (max-width:992px) {
						font-size: 16px;
						padding-bottom: 10px;
						padding-top: 8px;
						max-width: 240px;
					}

					@media (max-width:360px) {
						font-size: 13px;
						max-width: 186px;
					}


					i {
						font-size: 22px;
						margin-right: 10px;
						color: var(--white);
						position: relative;
						top: 1px;

						@media (max-width:360px) {
							font-size: 16px;
							margin-right: 4px;
						}

					}

					&:hover {
						background-color: var(--primary);
					}
				}
			}



			@media (max-width:992px) {
				margin-top: 10px;
			}

			.heading1 {
				margin-bottom: 10px;
			}

			.unit-pr-dt-gallery {
				@media (min-width:993px) {
					display: flex;
					justify-content: flex-end;

				}
			}

			.unit-dt-row {
				@media (min-width:1300px) {
					right: -45px;
				}

				@media (min-width:993px) {
					display: flex;
					justify-content: space-between;
					position: relative;
					right: -25px;
				}

				@media (max-width:992px) {
					margin-top: 5px;
					padding-bottom: 15px;
				}

				@media (max-width:480px) {
					margin-top: 10px;
				}

				@media (max-width:380px) {
					margin-top: -10px;
				}

				.back-btn {
					color: var(--primary);

					.fal {
						margin-right: 5px;
					}
				}

				.unit-dt-bl {
					margin-right: 20px;
					margin-top: 15px;

					@media (max-width:992px) {
						margin-top: 0;
						margin-right: 0;
					}

					>div {
						font-family: var(--d-font2);
						padding: 0 15px;
						display: inline-block;
						font-size: 18px;
						text-align: center;

						i {
							opacity: .2;

							@media (max-width:992px) {
								margin-right: 7px;

							}

							@media (min-width:993px) {
								margin-bottom: 12px;
							}
						}

						span {

							font-size: 18px;
							text-align: center;

							@media (min-width:993px) {
								display: block;
							}
						}

						@media (max-width:992px) {
							padding: 0 6px 0 0px;
						}
					}

					.tooltip {
						font-size: 12px;

						.tooltip-arrow {
							bottom: -5px;
						}
					}
				}
			}
		}

		.unit-head-section {
			margin: 0 0 30px;
			border-bottom: 1px solid #ecf3fd;

			.bg-blue {
				background-color: var(--primary);
			}


		}
	}


}