.faq-v2 {
	/* margin-top: var(--page-margin-top); */

	.card {
		background-color: transparent;
		border: 1px solid var(--color-primary);
		border-radius: 8px;
		overflow: hidden;

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
			border-radius: 0;
			overflow: hidden;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);
		position: relative;
		padding: 0 0 0 45px;
		min-height: 50px;
		display: flex;
		align-items: center;

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}

		&:before {
			font-family: Font Awesome\ 5 Free;
			font-weight: 700;
			left: 15px;
			top: auto;
			position: absolute;
			font-size: 18px;
			content: "\f055";
		}
	}

	.btn-link[aria-expanded=true]:before {
		content: "\f056";
	}

}