.unit-feedbacks {
	background-color: #f9f9f9;
	padding: 60px 0 40px;

	blockquote {
		border-left-color: #5f5f5f;
		border-left-color: var(--secondary);
		padding: 10px 20px;
		margin: 0 0 15px;
		font-size: 16px;
		line-height: normal;
		border-left: 5px solid var(--color-primary);
	}

	.ratings {
		.stars {
			margin-top: 0;
			color: var(--secondary);
			display: inline-flex;

			.fa {
				color: var(--secondary);
			}

		}

		.rating-gr-bl {
			background-color: var(--color-secondary-light);
			color: var(--color-white);
			box-shadow: 0 0 8px rgba(0, 0, 0, .15);
			max-width: 460px;
			margin-bottom: 15px;

			.rating-head {
				text-align: center;
				padding: 30px 20px;
				margin-bottom: 10px;

				h3 {
					margin-top: 0;
					font-family: var(--d-font2);
					font-weight: normal;
					font-size: 24px;
					color: var(--color-white);
				}

				.rating-st-bl {
					display: inline-flex;
					max-width: 205px;
					text-align: center;
					border-radius: 20px;
					padding: 8px 20px;
					background-color: var(--white);
					font-size: 18px;
					font-family: var(--d-font2);
					margin: 15px 0 0;

					.stars {
						font-size: 16px;
						margin-top: 0;
					}

					span {
						margin-right: 10px;
						color: var(--secondary);
					}
				}

				.blue-link {
					color: var(--secondary);
					text-decoration: underline;
				}
			}

			.rating-row {
				padding: 18px 20px;
				border-top: 2px solid #e2e6e8;

				@media (max-width:992px) and (min-width:768px) {
					flex-wrap: wrap;
					justify-content: center;
				}

				span {
					font-size: 18px;
					font-family: var(--d-font2);
				}

				.stars {
					font-size: 16px;

					@media all and (min-width: 768px) and (max-width: 991px) {
						width: 100%;
						text-align: center;
						align-items: center;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 5px 0 0 0;
					}
				}
			}
		}

		.feedbacks {
			.feed-row {
				display: none;

				.agency-comment {
					background-color: var(--color-grey-normal);
					padding: 15px;
					margin-left: 30px;
					margin-bottom: 30px;
				}

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					display: block;
				}
			}

			blockquote {
				p {
					font-family: var(--font-family-main);
				}

				footer {
					&:before {
						content: "-";
					}
				}
			}

			.rating-head {
				.stars {
					color: var(--primary);
					display: inline-block;
					margin-left: 15px;
				}
			}
		}


		.cl-feed-bl {
			@media (min-width:600px) {
				max-height: 220px;
				overflow: hidden;
			}
		}

		.cl-feedbl {
			box-shadow: 0 0 8px rgba(0, 0, 0, .15);
			padding: 20px;
			border-radius: 15px;
			background-color: var(--white);
			position: relative;
			min-height: 355px;

			@media (max-width:992px) {
				padding: 20px 30px;
				padding-top: 30px;
			}

			footer {
				font-size: 14px;
				position: absolute;
				bottom: 20px;
				color: var(--grey3);
			}

			i {
				font-size: 40px;
				position: absolute;
				color: var(--grey);
				top: -25px;
			}

			.cli-name {
				color: var(--secondary);
				font-family: var(--d-font2);
				font-weight: normal;
			}
		}
	}
}

.unit-feedbacks-v1 {
	.ptitle {
		display: inline-block;
	}

	.ratings {

		.rating {
			width: 100%;
			float: left;
			margin: 0 0 20px 0;

			.fa {
				color: var(--color-primary)
			}
		}
	}

	.hide-show-btn {
		font-size: var(--font-size-md);
	}

	.showLessFeedback {
		.feedbacks {
			.feedback {
				&:nth-child(n + 4) {
					display: none;
				}
			}
		}
	}

	.showFullFeedback {
		.feedbacks {
			.feedback {
				&:nth-child(n + 4) {
					display: block;
				}
			}
		}
	}



	.feedbacks {

		clear: left;
		padding-top: 20px;

		.feedback {
			border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 30px;
			border-radius: 8px;


			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: var(--font-size-md);
			}

			.author {
				font-size: var(--font-size-sm);
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--color-red);
				font-size: var(--font-size-md);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}