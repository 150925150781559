.unit-pricelist-v1 {

	.hide-show-btn {
		font-size: var(--font-size-md);
		color: var(--color-primary);
		font-weight: 700;
	}

	.showLessPrice {
		display: none;
	}

	.showFullPrice {
		margin: 20px 0 0 0;
		display: block;
	}

	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}
}