.search-page-layout-v1 {
	.list-view {
		background: #fff;

		.search-map-pane-v1 {
			display: none;
		}
	}

	.alert-info {
		background: #c0cec7;
		border-color: transparent;
		color: #fff;
	}

	.map-view {
		.results {
			display: none;
		}
	}
}