.search-unit-v1 {

	.show-mob {
		@media (max-width: 992px) {
			display: none !important;
		}

		@media (max-width: 991px) {
			display: block !important;
		}
	}

	.hide-mob {
		@media (min-width: 992px) {
			display: block !important;
		}
	}

	.img-content {
		.owl-prev {
			position: absolute;
			z-index: 1;
			color: #fff !important;
			font-size: 46px !important;
			line-height: 8px !important;
			text-align: center !important;
			height: 100%;
			top: 0;
			left: 0;
			background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%) !important;
			background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%) !important;
			background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%) !important;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=1);
			width: 50px;
			border-radius: 8px;
		}

		.owl-next {
			position: absolute;
			z-index: 1;
			color: #fff !important;
			font-size: 46px !important;
			line-height: 8px !important;
			text-align: center !important;
			height: 100%;
			top: 0;
			right: 0;
			background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%) !important;
			background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%) !important;
			background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%) !important;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=1);
			width: 50px;
			border-radius: 8px;
		}

		.owl-dots {
			display: none !important;
		}
	}

	.unit-row {
		padding: 15px;
		background-color: #fff;
		border-radius: 8px;
		margin: 10px 0 50px 0;
		margin-left: 15px;
		box-shadow: 0 0 5px rgb(0 0 0 / 18%);

		@media (min-width: 993xpx) {
			margin-left: 15px;
		}

		@media (max-width: 991px) {
			margin: 10px 15px 50px;
			padding: 0;
			box-shadow: none;
			background: transparent;
		}
	}

	.img-col {
		position: relative;
		overflow: hidden;
		margin-left: -15px;
		margin-top: -30px;


		@media (max-width: 1200px) {
			margin-right: 15px;
		}

		@media (max-width: 991px) {
			margin: 0px;
		}

		.item {
			@media (min-width: 992px) {
				height: 270px;
			}

			@media (max-width: 991px) {
				height: 293px;
			}
		}

		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
			border-radius: 8px;
		}
	}

	.info-col {
		@media (max-width: 1200px) {
			padding-top: 15px;
		}

		@media (max-width: 991px) {
			padding: 0px;
		}

		.info-col-inner {
			@media (max-width: 991px) {
				margin: 0 10px;
				padding: 15px;
				position: relative;
				background-color: #fff;
				background-color: var(--white);
				top: -35px;
				border-radius: 5px;
				z-index: 5;
				box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
			}
		}
	}

	.tick-li {

		li {
			font-size: var(--font-size-md);
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			display: inline-block;

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}

	}

	.fav-icon {
		position: absolute;
		right: 10px;
		top: 10px;
		color: #fff;
		cursor: pointer;
		z-index: 1;
	}

	.properties-row {
		padding: 15px 0;
		border-bottom: 1px solid var(--color-grey-normal);
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 15px;

		@media (min-width:993px) {
			padding-top: 45px;
		}

		.unit-distance {
			padding: 0 15px;
			font-weight: 600;
			right: 0;
			top: 0;
			font-size: 12px;
			line-height: 30px;
			color: var(--white);
			/*color: rgba(0, 0, 0, .7); */
			background-color: var(--color-secondary-light);
			border-radius: 15px;

			@media all and (min-width: 992px) and (max-width: 1199px) {
				margin: 5px 0 0 0;
			}

		}
	}

	.unit-distance {
		@media (max-width: 991px) {
			top: -36px;
			background-color: var(--white);
			border-radius: 15px;
			position: absolute;
			padding: 0 15px;
			font-weight: 600;
			right: 0;
			font-size: 12px;
			line-height: 30px;
			color: rgba(0, 0, 0, .7);
		}
	}

	.unit-properties {
		padding-bottom: 0;
		margin: 0;
		display: inline-flex;
		flex-wrap: wrap;

		@media all and (min-width: 993px) and (max-width: 1199px) {
			width: 100%;
		}

		li {
			padding-right: 10px;
			display: inline-flex;
			align-items: center;

			@media (max-width:767px) {
				padding-right: 8px;
				font-size: 15px;
			}

			.fa,
			.fas {
				width: auto;
				margin-right: 7px;
				display: inline-flex;

				@media (max-width:767px) {
					margin-right: 5px;
				}

				.fa-stack {
					margin-left: -10px;
					line-height: 1.4em;
				}
			}

			.fa-dog {
				width: auto;
			}

			.small-dog {
				width: auto;
				position: absolute;
				bottom: 0px;
				left: 6px;
				font-size: 14px;
			}
		}
	}

	.unit-price-col {
		display: block;

		.unit-act-row {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.btn-primary {
				max-width: 150px;
				width: 100%;
				margin: 0 !important;
			}
		}
	}

	.rent {
		text-align: center;
	}

	.small {
		font-size: var(--font-size-md);

		@media (min-width: 1201px) {
			&:after {
				content: "\A";
			}
		}
	}

	.main-price {
		font-weight: 600;
		text-decoration: line-through;
	}

	.discount-price {
		font-weight: 600;

		.reducedprice {
			color: var(--color-red);
		}
	}

	.prices {
		text-align: left;
		width: 100%;

		.rentRate {
			p {
				margin: 0 !important;
			}
		}

		@media (max-width: 1200px) {
			text-align: left;
		}

	}

	.offer-box {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		color: var(--color-black);
		border-top: 1px dashed var(--color-grey-normal);
		border-bottom: 1px dashed var(--color-grey-normal);
		margin-top: 15px;

		@media (max-width:1200px) {
			display: block
		}

		.offer-left {
			background: var(--color-secondary-light);
			color: var(--color-black);
			padding: 10px 15px;
			border: 1px dashed var(--color-primary);
			font-size: 16px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-right: 15px;

			@media (max-width:1200px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding-top: 8px;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 5px;
				line-height: 14px;
				font-size: 14px
			}
		}
	}

	.alternatives {
		margin-top: 15px;
		background-color: transparent;
		padding: 10px 10px 1px;
		border-top: 1px solid #f2f2f2;

		@media (max-width: 1199px) {
			border-top: 0 solid #f2f2f2;
			border-bottom: 1px solid #f2f2f2;
			margin-bottom: 15px;
			padding-top: 0;
		}

		.altheading {
			font-weight: 600;
			color: var(--color-red);
			display: block;
			width: 100%;
			margin-bottom: 10px;
		}

		ul {
			background: var(--color-grey-light);
			padding: 10px 15px;
			color: #444;
			color: var(--color-grey-dark);
			border: 1px solid #919191;
			border: 1px solid var(--color-grey-normal);
			border-bottom: 1px solid var(--color-grey-normal);
			border-radius: 8px;
		}



		a {
			color: var(--font-color-main);
			text-decoration: underline !important;

			&:hover {
				text-decoration: none !important;
				color: var(--color-red);
			}


		}

		.fa {
			color: var(--color-red);
			font-size: 12px;
			line-height: 16px;
		}

		.invalid {
			text-decoration: line-through;
		}

		.special {
			color: var(--color-red);
			font-weight: 600;
		}

	}

	.pets {
		position: relative;

		.max-pets {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: -3px;
			left: 19px;
			width: 16px;
			height: 16px;
			background: var(--color-grey-dark);
			color: var(--color-white);
			text-align: center;
			font-size: 10px;
			line-height: 16px;

		}
	}



}