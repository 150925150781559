.unit-calendar {

	.asd__wrapper {
		border-radius: 8px;
	}

	.datepicker-trigger {

		display: inline-block;

		.asd__day--disabled,
		.asd__day--empty {
			opacity: 1.0 !important;
			cursor: not-allowed;

		}

		.asd__day-button {
			pointer-events: auto;
		}

		.asd__day-button:disabled,
		.asd__day-button[disabled] {
			pointer-events: none;

		}

		.asd__day--disabled {
			background: #f5f5f5 !important;
			color: var(--unit-click-not-possible-color) !important;

			.asd__day-button:disabled,
			.asd__day-button[disabled] {
				pointer-events: none;

			}
		}

		.asd__day--ar-pos.asd__day--scope {

			/* .asd__day-button:disabled,
			.asd__day-button[disabled] {
				text-decoration: unset;
				pointer-events: unset;
			} */
		}

		.asd__day--enabled.asd__day--disabled.asd__day--in-range.asd__day--scope.asd__day--no-arrival {

			.asd__day-button:disabled,
			.asd__day-button[disabled] {
				text-decoration: unset;
				pointer-events: unset;
			}
		}

		.asd__day--enabled.asd__day--disabled.asd__day--selected.asd__selected-date-two.asd__day--arrival.asd__day--scope.asd__day--no-arrival {

			.asd__day-button:disabled,
			.asd__day-button[disabled] {
				text-decoration: unset;
				pointer-events: unset;
			}
		}

		.asd__day--enabled.asd__day--disabled.asd__day--selected.asd__selected-date-two.asd__day--scope.asd__day--no-arrival {

			.asd__day-button:disabled,
			.asd__day-button[disabled] {
				text-decoration: unset;
				pointer-events: unset;
			}
		}

		.asd__day--selected.asd__selected-date-two {

			/* .asd__day-button:disabled,
			.asd__day-button[disabled] {
				text-decoration: unset;
				pointer-events: unset;
			} */
		}

		.asd__day--in-range {

			/* .asd__day-button:disabled,
			.asd__day-button[disabled] {
				text-decoration: unset; 
				pointer-events: unset;
			} */
		}

		/* .asd__day--scope {
			background: var(--unit-click-not-possible-bg) !important;
			color: var(--unit-click-not-possible-color) !important;
		} */



		.asd__day--ar-pos,
		.asd__day--ar-pos:not(.asd__day--disabled) {
			background: var(--unit-click-possible-bg) !important;
			color: var(--unit-click-possible-color) !important;
			font-weight: 500;

		}



		.asd__day--no-arrival {
			background: var(--unit-click-not-possible-bg) !important;
			color: var(--unit-click-not-possible-color) !important;

		}



		.asd__day--arrival {
			background: linear-gradient(135deg, var(--unit-click-not-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important
		}

		.asd__day--arrival:not(.asd__day--disabled) {
			background: linear-gradient(135deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important
		}


		.asd__day--departure {
			background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-not-possible-bg) 50%) !important;
		}

		.asd__day--departure:not(.asd__day--disabled) {
			background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important;
		}



		.asd__day--not-available {
			background: var(--unit-cal-not-available-bg) !important;
			color: var(--unit-cal-not-available-color) !important;
			opacity: 1 !important;
		}

		.asd__day--disabled.asd--day--in-range {
			background: var(--unit-cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
		}

		.asd__day--enabled:not(.asd__day--disabled, .asd__day--arrival, .asd__day--departure, .asd__day--in-range, .asd__selected-date-one, .asd__selected-date-two) {
			background: var(--unit-click-possible-bg) !important;
			color: var(--unit-click-possible-color) !important;
			font-weight: 500 !important;

			&:hover {
				background: var(--unit-cal-selected) !important;
				color: var(--unit-cal-text-color-hover) !important;
			}
		}

		/* .asd__day--enabled.asd__day--no-arrival {
			background: var(--unit-click-possible-bg) !important;
			color: var(--unit-click-possible-color) !important;
		} */
		.asd__day--ar-pos.asd__day--disabled.asd__day--enabled {
			background: #fff !important;
			color: var(--unit-click-not-possible-color) !important;
			font-weight: normal !important;
		}

		.asd__day--ar-pos.asd__day--disabled.asd__day--enabled.asd__day--selected.asd__selected-date-one {
			background: var(--unit-cal-selection-range) !important;
			color: var(--unit-cal-text-color-selected) !important;
			font-weight: 500 !important;
		}



		.asd__day--enabled.asd__day--in-range {
			background: var(--unit-cal-selection-range) !important;
			color: var(--unit-cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
		}

		/* .asd__day--enabled:not(.asd__day--disabled) {
			background: var(--unit-click-possible-bg) !important;
			color: var(--unit-click-possible-color) !important;

			&:hover {
				background: var(--unit-cal-selected) !important;
				color: var(--unit-cal-text-color-hover) !important;
			}
		} */

		.asd__day--enabled:not(.asd__day--disabled) {
			&:hover {
				background: var(--unit-cal-selected) !important;
				color: var(--unit-cal-text-color-hover) !important;
			}
		}

		.asd__day--disabled.asd__day--hovered.asd__day--scope {
			background: var(--color-primary) !important;
			opacity: 1 !important;
			border: inherit;
			color: #fff !important;
		}

		.asd__day--enabled.asd__day--hovered.asd__day--scope {

			opacity: 1 !important;
			border: inherit;
			color: inherit;
			color: #fff !important;
			font-weight: 500 !important;
		}


		.asd__day--selected {
			background: var(--unit-cal-selected) !important;
			color: var(--unit-cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
			font-weight: 500 !important;

			/*Arrow display css start*/
			&.asd__selected-date-one {
				position: relative;
				background: var(--unit-cal-selection-range) !important;
				color: var(--unit-cal-text-color-selected) !important;
				font-weight: 500 !important;
				/* &:before {
					content: "";
					position: absolute;
					border-left: 12px solid transparent;
					border-top: 19px solid var(--unit-cal-selection-range);
					border-bottom: 20px solid var(--unit-cal-selection-range);
					right: 0px;
				} */
			}

			&.asd__selected-date-two {
				position: relative;
				background: var(--unit-cal-selection-range) !important;
				color: var(--unit-cal-text-color-selected) !important;
				font-weight: 500 !important;

				/* &:before {
					content: "";
					position: absolute;
					border-right: 12px solid transparent;
					border-top: 19px solid var(--unit-cal-selection-range);
					border-bottom: 20px solid var(--unit-cal-selection-range);
					left: 0px;
				} */
			}
		}

		&.searchbox {
			width: 100%;
		}

	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;
		color: var(--color-black);
		font-size: var(--font-size-md);

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;

			@media (max-width:767px) {
				justify-content: center;
			}
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			@media (max-width:767px) {
				justify-content: center;
			}

			&:first-child {
				@media (max-width:992px) {
					width: 100%;
				}
			}

			.day {
				width: 24px;
				height: 24px;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
					margin: 0 6px 0 0;
				}

				&.day-free {
					background: var(--unit-click-possible-bg);
				}

				&.day-full {
					background: var(--unit-cal-not-available-bg);
				}

				&.day-disabled {
					background: var(--unit-click-not-possible-bg);
				}

				&.day-selected-range {
					background: var(--unit-cal-selection-range);
				}

				&.day-end {
					background: linear-gradient(135deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important;
				}

				&.day-start {
					background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important
				}
			}
		}
	}
}

.tooltip.datepicker.show {
	opacity: 1 !important;
}

.datepicker {

	.tooltip-inner {
		background-color: white;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #999;
		padding: 0.25rem 0.25rem;
	}

	.arrow {
		position: absolute;
		display: block;
		width: 0.8rem;
		height: 0.4rem;

		&:before {
			top: 0;
			border-width: 0.4rem 0.4rem 0;
			border-top-color: #fff;
		}
	}
}