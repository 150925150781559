.blog-card-v1 {
	margin-bottom: 20px;
	width: 100%;
	box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	background-color: var(--color-white);
	padding: 10px;
	border-radius: 8px;

	&:hover {
		box-shadow: 0 4px 25px 0 rgb(0 0 0 / 50%);
		-webkit-transform: translateX(1px);
		transform: translateX(1px);
	}

	.image {
		height: 250px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.blog-body {
		padding-top: 10px;
	}

	.date {
		position: absolute;
		font-size: 0.85rem;
		background-color: var(--color-primary);
		padding: 2px 5px;
		color: var(--color-white);
		bottom: 10px;
		right: 10px;

	}

	.title {
		font-size: var(--blog-card-title-font-size);
		color: var(--blog-card-title-font-color);
		min-height: 60px;
		font-weight: 600;
	}

	.link {
		text-align: right;
	}
}