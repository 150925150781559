.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		/*border-top: 1px solid var(--footer-border-top-color);*/
		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}


		.logo {
			margin-top: -40px;
			padding-bottom: 20px;
			max-width: 100%;
			width: auto;
			height: auto;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}

		.footer-4 {
			ul {
				li {
					position: relative;
					margin-bottom: 20px;
					padding-left: 30px;

					i {
						position: absolute;
						left: 0;
						top: 1px;
						color: var(--color-primary);
						font-size: 18px;
					}

				}
			}
		}


		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}