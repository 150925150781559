.verwaltung-form-v1 {
	background-color: #c0cec7;
	border-radius: 8px;

	.red {
		color: var(--color-red);
	}

	.checkbox {
		label {
			vertical-align: inherit;
		}
	}

}