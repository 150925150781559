.unit-title-v1 {

	@media (max-width: 767px) {
		padding: 30px 0 0 0;
	}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}
}