.contact-form-v1 {
	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	background-color: #c0cec7;
	border-radius: 8px;
}